.footer {
  position: relative;
  background: #123f9b;
  background: linear-gradient(to left, #123f9b 0%, #2b64cd 100%);
  padding: 60px 0 100px;
  margin-top: auto;
  overflow: hidden;

  &::before {
    position: absolute;
    display: block;
    content: "";
    width: 110%;
    height: 100px;
    background: white;
    left: -2px;
    top: -72px;
    transform: rotate(-1.5deg);
  }

  &__copyright {
    text-align: center;

    &-text {
      color: white;
      font-size: 0.8rem;
      display: block;
    }
  }

  .sign {
    position: relative;
    margin-left: 5px;
    color: white;
    top: 1px;

    &:hover {
      text-decoration: none;
    }
  }

  &__logo {
    height: 25px;

    img {
      display: block;
      height: 25px;
      margin: 10px auto;
    }
  }

  .btn-cookies {
    position: absolute;
    bottom: 2.1rem;
    right: 1rem;
  }
}

@include media-breakpoint-up(md) {
  .footer {
    padding: 60px 0;

    &__copyright {
      @include clearfix;
      display: table;
      margin: 0 auto;

      &-text {
        display: inline-block;
      }
    }

    &__logo {
      display: inline-block;
      margin-left: 10px;

      img {
        display: inline;
        margin: 0;
      }
    }
  }
}
